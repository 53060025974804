body {

  background: #ffffff;
  font-family: Helvetica;
  overscroll-behavior: contain; /* prevents pull down refresh */

}

button {

    cursor: pointer; /* needed for iphone/ipad for click function --> https://stackoverflow.com/questions/3705937/document-click-not-working-correctly-on-iphone-jquery */

}

tr:hover {background-color: #FAD7A0;}

tr {cursor: pointer;}

th,td {

  font-size: 15px;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #d3d3d3;

}

th {
  position: sticky;
  top: 0px;
  background-color: white
}

.icons {

    background-color: transparent;
    border: none;
    color: dodgerblue;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin: 3px;
    outline: 'none';
    padding: 3px;
    width: 30px;
    vertical-align: top;

}

.leftPanelLink {
  border-radius: 10px;
  display: block;
  color: #d3d3d3;
  font-size: 15px;
  margin: 10px;
  text-decoration: none;
}

.leftPanelLink:hover {
  background-color: dodgerblue
}

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

.input_btns {

    display: flex;
    justify-content: space-between;

}

/* ====SCROLL BAR==== */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .btn_search {

    color: dodgerblue;
    vertical-align: middle;

} */



/* .btn_search-clear {

    background-color: transparent;
    border: none;
    color: tomato;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    vertical-align: middle;

} */

.hoverLink:hover {background-color: #FAD7A0;}

.select {

  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin: 10px 10px 10px 0px;

}

.input {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: inline-block;
  height: 40px;
  margin: 10px 10px 10px 0px;
}

.label {
  /* border: 1px solid #d3d3d3; */
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 10px 0px 10px;
}

.textArea {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: inline-block;
  height: 100px;
  margin: 10px 10px 10px 0px;
  width: calc(100% - 30px);
  max-width: 600px;
}

.wrapText {
  word-wrap: break-word;
  max-width: 150px;
}

/* Slider */

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: orange;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: dodgerblue;
}

input:focus + .slider {
  box-shadow: 0 0 1px dodgerblue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}

/*                */
